// Components
import BrandCardCentered from '@/components/ui/BrandCardCentered'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

// Services
import { recoverPassword } from '@/firebase/authentication'

export default {
  name: 'Login',
  components: { BrandCardCentered, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],

  data() {
    return {
      // Form
      formFields: {
        email: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        }
      }
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        large: true
      }
    }
  },
  methods: {
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      try {
        await recoverPassword(this.formFields.email)
        // Show info
        this.modifyAppAlert({
          text: 'Se ha enviado un email con un enlace para cambiar la contraseña',
          visible: true
        })
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text:
            error.code === 'auth/user-not-found'
              ? 'El email introducido no pertenece a ninguna cuenta'
              : error.message,
          visible: true,
          type: 'error'
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      email: {
        required,
        email
      }
    }
  }
}
